var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer-cont" }, [
    _c("div", { staticClass: "footer-trap" }, [
      _c("div", {
        staticClass: "info",
        domProps: { innerHTML: _vm._s(_vm.footerText) }
      })
    ]),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "footer-inner" }, [
        _c("div", { staticClass: "logo" }),
        _c("div", { staticClass: "divider" }),
        _c("div", {
          staticClass: "info",
          domProps: { innerHTML: _vm._s(_vm.fixHyphens(_vm.footerText)) }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }