var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile-bar" }, [
    _c("div", { staticClass: "chapter-list-mobile" }, [
      _c("p", {
        staticClass: "dropdown-header",
        domProps: { textContent: _vm._s(this.currentHeading) },
        on: {
          click: function($event) {
            return _vm.toggleSelect()
          }
        }
      }),
      _c("button", {
        staticClass: "select-button",
        attrs: { tabindex: "" },
        on: {
          click: function($event) {
            return _vm.toggleSelect()
          }
        }
      }),
      _c(
        "ul",
        { class: { "nav-is-visible": _vm.displaySelectMenu } },
        _vm._l(_vm.course.Chapters, function(chapterObj) {
          return _c("li", [
            _c(
              "p",
              {
                key: chapterObj.Id,
                class: {
                  "is-current-page": _vm.currentHeading === chapterObj.Heading
                },
                attrs: { tabindex: "" },
                on: {
                  click: function($event) {
                    return _vm.select(chapterObj.Heading, chapterObj.Id, 0)
                  }
                }
              },
              [
                _c(
                  "a",
                  { attrs: { tabindex: "", href: "javascript:void(0)" } },
                  [_vm._v(_vm._s(chapterObj.Heading))]
                )
              ]
            ),
            chapterObj.Methods.length > 0
              ? _c(
                  "ul",
                  _vm._l(chapterObj.Methods, function(methodObj, index) {
                    return _c("li", [
                      _c(
                        "p",
                        {
                          key: index + 1,
                          class: {
                            "is-current-page":
                              _vm.chapterId == chapterObj.Id &&
                              _vm.step == index + 1
                          },
                          on: {
                            click: function($event) {
                              return _vm.select(
                                methodObj.Heading,
                                chapterObj.Id,
                                index + 1
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                tabindex: "",
                                href: "javascript:void(0)"
                              }
                            },
                            [_vm._v(_vm._s(methodObj.Heading))]
                          )
                        ]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e()
          ])
        }),
        0
      )
    ]),
    _c(
      "div",
      { staticClass: "progressbar-outer" },
      [
        _c("progress-bar", {
          staticClass: "progressbar-mobile",
          attrs: { course: _vm.course, chapter: _vm.chapter }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }