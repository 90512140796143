var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("router-view"),
      _c("header", { staticClass: "top-bar-container" }, [_c("StatusBar")], 1),
      _c("main", { staticClass: "main", attrs: { role: "main" } }, [
        _c("div", { staticClass: "home" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "elearning-box" }, [
                _c("div", { staticClass: "elearning-box-inner" }, [
                  _c("div", { staticClass: "info-cont" }, [
                    _c("h1", {
                      staticClass: "elearning-box-heading",
                      domProps: { textContent: _vm._s(_vm.startHeading) }
                    }),
                    _c("div", {
                      staticClass: "elearning-box-text",
                      domProps: { innerHTML: _vm._s(_vm.startText) }
                    })
                  ]),
                  _c("div", { staticClass: "image-cont" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.startImage,
                        alt: _vm.startAltText,
                        role: "presentation"
                      }
                    })
                  ])
                ])
              ]),
              _c("courses-list", { attrs: { id: "content" } })
            ],
            1
          )
        ])
      ]),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }