



















































import { Component, Vue, Prop } from 'vue-property-decorator';
import StatusBar from '@/components/StatusBar.vue';
import Footer from '@/components/Footer.vue';
import PurpleButton from '@/components/PurpleButton.vue';
import ChapterList from '@/components/ChapterList.vue';
import ChapterProgress from '@/components/ChapterProgress.vue';
import {Chapter} from '@/types/Chapter';
import {Course} from '@/types/Course';
import ChapterListMobile from "@/components/ChapterListMobile.vue";

@Component({
    components: {ChapterListMobile, Footer, StatusBar, PurpleButton, ChapterList, ChapterProgress },
})

export default class CourseInfo extends Vue {
    @Prop() public courseId!: number;
    @Prop() public chapterId!: number;
    @Prop() private course!: Course;
    public header: string = '';
  


    private triggerGAEventStartChapter(chapterHeading:string) {
        this.$ga.event(`Course ${this.course.Heading}`, 'click', `Start Chapter ${chapterHeading}`);
    }

    public fixhyphens(text: string) {
        return text.replace(/(\S+)-(\S+)/g, '$1&#8288;-&#8288;$2');
    }

    private triggerGAEventEndCourse() {
        this.$ga.event(`Course ${this.course.Heading}`, 'click', `End Course`);
    }

    get realChapterId() {
        if (this.chapterId) {
            return this.chapterId;
        }
        else {
            const chapId = this.course.Chapters[0].Id
            this.$store.dispatch('saveChapterId', chapId);
            return chapId;
            
        }
    }

    get chapter() {
        for (let i = 0, j = this.course.Chapters.length; i < j; i++) {
            if (this.course.Chapters[i].Id == this.realChapterId) {
                this.header = 'h' + (this.course.Chapters[i].HeadingLevel > 0 ? this.course.Chapters[i].HeadingLevel : 3);
                return this.course.Chapters[i];
            }
        }
        return false;
    }

    get publicPath() {
        return process.env.VUE_APP_CONTENTURL;
    }
}
