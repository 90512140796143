



































import { Component, Prop, Vue } from 'vue-property-decorator';
import {CheckboxQuestion} from '@/types/Methods/CheckboxQuestion';
import { Option } from '@/types/Methods/Option';
    import { Shuffle } from '@/helpers/Helpers';
    import { Fixhyphens } from '@/helpers/Helpers';
@Component
export default class CheckboxQuestionMethod extends Vue {

    @Prop() private method!: CheckboxQuestion;
    @Prop() private complete!: boolean;

    private fixHyphens = Fixhyphens;
    private shuffledOptions: any = [];
    private correctAnswer: boolean = false;
    private validated: boolean = false;

    get options() {
        if (this.shuffledOptions.length === 0) {
            this.shuffledOptions = Shuffle(this.method.Options);
            for (let i = 0; i < this.shuffledOptions.length; i++) {
                Vue.set(this.shuffledOptions[i], 'Checked', false);
                Vue.set(this.shuffledOptions[i], 'CorrectAnswer', false);
            }
        }
        return this.shuffledOptions;
    }

    private clickOption(option: Option) {
        option.Checked = !option.Checked;
    }
    private validateOptions() {
        this.correctAnswer = true;
        if (!this.shuffledOptions) {
            return;
        }

        for (let i = 0; i < this.shuffledOptions.length; i++) {
            const option = this.shuffledOptions[i];
            if ((option.Correct && !option.Checked) || (!option.Correct && option.Checked) ) {
                this.correctAnswer = false;
                option.CorrectAnswer = false;
            } else {
                option.CorrectAnswer = true;
            }
        }

        this.setValidated();
    }

    private setValidated() {
        this.validated = true;
    }

    get header() {
        return 'h' + (this.method.HeadingLevel > 0 ? this.method.HeadingLevel : 3);
    }
}
