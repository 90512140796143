var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-innercontent-content continue-chapter" },
    [
      _c("div", { staticClass: "close-button" }, [
        _c("div", { staticClass: "purple-button" }, [
          _c(
            "button",
            {
              staticClass: "button secondary close",
              attrs: { tabindex: "", "aria-label": "Stäng fönster" },
              on: {
                click: function($event) {
                  return _vm.cancelCourse()
                }
              }
            },
            [_c("b", [_vm._v("+")])]
          )
        ])
      ]),
      _vm._m(0),
      _c("div", { staticClass: "modal-buttons" }, [
        _c("div", { staticClass: "purple-button" }, [
          _c(
            "button",
            {
              staticClass: "button primary arrow",
              attrs: { tabindex: "" },
              on: {
                click: function($event) {
                  return _vm.continueCourse()
                }
              }
            },
            [_vm._v("Fortsätt där jag slutade")]
          )
        ]),
        _c("div", { staticClass: "purple-button" }, [
          _c(
            "button",
            {
              staticClass: "button secondary arrow",
              attrs: { tabindex: "" },
              on: {
                click: function($event) {
                  return _vm.restartCourse()
                }
              }
            },
            [_vm._v("Starta om utbildningen")]
          )
        ]),
        _c("div", { staticClass: "center-link" }, [
          _c(
            "a",
            {
              attrs: { tabindex: "", href: "javascript:void(0)" },
              on: { click: _vm.cancelCourse }
            },
            [_vm._v("Till startsidan")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-message" }, [
      _c("h1", [_vm._v("Fortsätt utbildningen")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }