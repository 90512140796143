var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "checkbox-question-method method-module-container",
      attrs: { role: "form" }
    },
    [
      _c(_vm.header, {
        tag: "tag",
        staticClass: "method-heading",
        domProps: { textContent: _vm._s(_vm.method.Heading) }
      }),
      _c("div", {
        staticClass: "method-intro",
        domProps: { innerHTML: _vm._s(_vm.fixHyphens(_vm.method.Text)) }
      }),
      _c(
        "div",
        { staticClass: "options" },
        _vm._l(_vm.options, function(option, index) {
          return _c("div", { key: index, staticClass: "option" }, [
            _c(
              "div",
              {
                staticClass: "checkbox",
                class: {
                  checked: option.Checked,
                  correct: option.CorrectAnswer > -1 && _vm.validated
                },
                on: {
                  click: function($event) {
                    return _vm.clickOption(option)
                  }
                }
              },
              [_c("div", { staticClass: "check" })]
            ),
            _c("div", {
              staticClass: "label",
              domProps: { textContent: _vm._s(option.Text) },
              on: {
                click: function($event) {
                  return _vm.clickOption(option)
                }
              }
            })
          ])
        }),
        0
      ),
      _c("div", { staticClass: "purple-button" }, [
        _c(
          "button",
          {
            staticClass: "button primary",
            on: {
              click: function($event) {
                return _vm.validateOptions()
              }
            }
          },
          [_vm._v("Rätta")]
        )
      ]),
      _vm.validated && _vm.correctAnswer
        ? _c("div", { staticClass: "response success" }, [
            _c("div", {
              staticClass: "success-text response-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.method.SuccessText !== "" &&
                    _vm.method.SuccessText !== undefined
                    ? _vm.method.SuccessText
                    : "<p><strong>Rätt svar</strong></p>"
                )
              }
            })
          ])
        : _vm._e(),
      _vm.validated && !_vm.correctAnswer
        ? _c("div", { staticClass: "response fail" }, [
            _c("div", {
              staticClass: "fail-text response-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.method.FailText !== "" &&
                    _vm.method.FailText !== undefined
                    ? _vm.method.FailText
                    : "<p><strong>Fel svar.</strong></p>"
                )
              }
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }