var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "quickshot-questions method-module",
      attrs: { role: "form" }
    },
    [
      _c(
        "div",
        {
          staticClass: "quickshot-questions-container method-module-container",
          attrs: { "aria-haspopup": _vm.method.Questions.length > 0 }
        },
        [
          _c(_vm.header, {
            tag: "tag",
            staticClass: "method-heading",
            domProps: { textContent: _vm._s(_vm.method.Heading) }
          }),
          _vm.method.Text
            ? _c("div", {
                staticClass: "method-intro",
                domProps: { innerHTML: _vm._s(_vm.fixHyphens(_vm.method.Text)) }
              })
            : _vm._e(),
          _vm._l(_vm.method.Questions, function(question, index) {
            return _c(
              "div",
              { key: index, staticClass: "questions" },
              [
                _c("transition", { attrs: { name: "question" } }, [
                  _vm.questionsCompleted == index
                    ? _c("div", { staticClass: "question" }, [
                        _c("p", {
                          staticClass: "question-text",
                          domProps: { textContent: _vm._s(question.Question) }
                        }),
                        _vm.questionsCompleted === index
                          ? _c("div", { staticClass: "question-buttons" }, [
                              _c("div", { staticClass: "purple-button" }, [
                                _c("button", {
                                  staticClass: "button secondary",
                                  class: {
                                    animate:
                                      _vm.animate[0] === index &&
                                      _vm.animate[1] === 1
                                  },
                                  domProps: {
                                    textContent: _vm._s(question.Alternative1)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.answerQuestion(index, 1)
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "purple-button" }, [
                                _c("button", {
                                  staticClass: "button secondary",
                                  class: {
                                    animate:
                                      _vm.animate[0] === index &&
                                      _vm.animate[1] === 2
                                  },
                                  domProps: {
                                    textContent: _vm._s(question.Alternative2)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.answerQuestion(index, 2)
                                    }
                                  }
                                })
                              ])
                            ])
                          : _vm._e()
                      ])
                    : index < _vm.questionsCompleted
                    ? _c("div", { staticClass: "question" }, [
                        _c("p", { staticClass: "question-text" }, [
                          _vm._v(_vm._s(question.Question))
                        ]),
                        _c("div", { staticClass: "question-buttons" }, [
                          _c("div", { staticClass: "purple-button" }, [
                            _c("button", {
                              staticClass: "secondary button validated",
                              class: {
                                animate:
                                  _vm.animate[0] === index &&
                                  _vm.animate[1] === 1,
                                incorrect: !question.FirstAlternativeCorrect,
                                correct: question.FirstAlternativeCorrect,
                                selected: question.FirstSelected,
                                unselected: !question.FirstSelected
                              },
                              domProps: {
                                textContent: _vm._s(question.Alternative1)
                              },
                              on: {
                                click: function($event) {
                                  return _vm.answerQuestion(index, 1)
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "purple-button" }, [
                            _c("button", {
                              staticClass: "secondary button validated",
                              class: {
                                animate:
                                  _vm.animate[0] === index &&
                                  _vm.animate[1] === 2,
                                incorrect: question.FirstAlternativeCorrect,
                                correct: !question.FirstAlternativeCorrect,
                                selected: question.SecondSelected,
                                unselected: !question.SecondSelected
                              },
                              domProps: {
                                textContent: _vm._s(question.Alternative2)
                              },
                              on: {
                                click: function($event) {
                                  return _vm.answerQuestion(index, 2)
                                }
                              }
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "response" }, [
                          _c("div", {
                            staticClass: "response-text",
                            domProps: {
                              innerHTML: _vm._s(
                                (question.FirstSelected &&
                                  !question.FirstAlternativeCorrect) ||
                                  (question.SecondSelected &&
                                    question.FirstAlternativeCorrect)
                                  ? question.FailText
                                  : question.CorrectText
                              )
                            }
                          })
                        ])
                      ])
                    : _vm._e()
                ])
              ],
              1
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }