var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tips-cont",
      class: {
        open: _vm.isVisible /*TODO */,
        closed: !_vm.isVisible /*TODO*/,
        hidden: _vm.tipstext === "" || _vm.tipstext === undefined
      }
    },
    [
      _c("div", { staticClass: "overlay", class: { animate: _vm.animate } }),
      _c("div", { staticClass: "tips" }, [
        _c("div", { staticClass: "tips-box" }, [
          _c("div", { staticClass: "box-inner" }, [
            _c("div", { staticClass: "label", on: { click: _vm.toggleTips } }, [
              _c("div", { staticClass: "label-inner" }, [
                _vm._v(_vm._s(_vm.tipsLabel))
              ])
            ]),
            _c("div", { staticClass: "close", on: { click: _vm.toggleTips } }, [
              _vm._v("Stäng")
            ]),
            _c("div", { staticClass: "tips-content" }, [
              _c("h4", {
                staticClass: "heading",
                domProps: { textContent: _vm._s(_vm.tipsheading) }
              }),
              _c("div", {
                staticClass: "text",
                domProps: { innerHTML: _vm._s(_vm.tipstext) }
              })
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }