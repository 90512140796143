




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { CurrentStep } from '../../types/CurrentStep';
import { ProgressService } from '../../services/ProgressService';
import PurpleButton from '../PurpleButton.vue';

@Component({
    components: {
        PurpleButton,
    },
})
export default class ContinueOrResetModal extends Vue {
    @Prop() private props!: any;
    private ps = new ProgressService();
    private currentStep!: CurrentStep | undefined;

    public cancelCourse() {
        this.$store.commit('hideModal');
        this.$router.push('/');
    }

    public continueCourse() {
        this.currentStep = this.ps.currentStep(this.$store.state, this.props.courseId);
        if (this.currentStep) {
        this.$router.replace({
            name: 'method',
            params: {
                step: this.currentStep.Step.toString(),
                chapterId: this.currentStep.Chapter.toString(),
                courseId: this.currentStep.Course.toString()},
            });
        }
        this.$store.commit('hideModal');
    }
    public restartCourse() {
        this.$store.commit('hideModal');
        this.$store.dispatch('resetChapterProgressAndStep', this.props.courseId);
        if (this.currentStep) {
        this.$router.push({
            name: 'course',
            params: {
                courseId: this.currentStep.Course.toString()},
            });
        }
    }
}
