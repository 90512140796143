var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container course-page" },
    [
      _c("chapter-list-mobile", {
        attrs: {
          currentHeading: _vm.chapter.Heading,
          courseId: _vm.courseId,
          chapterId: _vm.chapterId,
          course: _vm.course
        }
      }),
      _c("chapter-progress", {
        attrs: { course: _vm.course, chapter: _vm.chapter }
      }),
      _c("div", { staticClass: "course-content-outer" }, [
        _c("main", { staticClass: "course-content" }, [
          _c("div", { staticClass: "course-inner" }, [
            _c("div", { staticClass: "columns" }, [
              _c(
                "section",
                {
                  staticClass: "course-info left-column",
                  attrs: { id: "content" }
                },
                [
                  _c(_vm.header, {
                    tag: "tag",
                    staticClass: "heading",
                    domProps: { innerHTML: _vm._s(_vm.chapter.Heading) }
                  }),
                  _c("div", { staticClass: "text" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.fixhyphens(_vm.chapter.Text))
                      }
                    })
                  ]),
                  _c("nav", { staticClass: "navigation buttons" }, [
                    _c(
                      "div",
                      { staticClass: "purple-button" },
                      [
                        _c("router-link", {
                          staticClass: "button secondary back back-button",
                          attrs: { to: { path: "/" } },
                          domProps: { textContent: _vm._s("Bakåt") },
                          nativeOn: {
                            click: function($event) {
                              return _vm.triggerGAEventEndCourse()
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "purple-button forward" },
                      [
                        _c("router-link", {
                          staticClass: "button primary arrow",
                          attrs: {
                            to: {
                              path:
                                "/course/" +
                                _vm.course.Id +
                                "/chapter/" +
                                _vm.realChapterId +
                                "/1"
                            }
                          },
                          domProps: { textContent: _vm._s("Påbörja kapitel") },
                          nativeOn: {
                            click: function($event) {
                              return _vm.triggerGAEventStartChapter(
                                _vm.chapter.Heading
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "aside",
                { staticClass: "column right-column" },
                [
                  _c("chapter-list", {
                    attrs: {
                      course: _vm.course,
                      selectedChapterId: _vm.chapterId ? _vm.chapterId : 0
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }