




























    import {Component, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import CourseItem from '../components/CourseItem.vue';
    import StatusBar from '../components/StatusBar.vue';
    import Footer from '../components/Footer.vue';
    import CoursesList from '../components/CoursesList.vue';
    import PurpleButton from '../components/PurpleButton.vue';
    import {ProgressService} from '../services/ProgressService';

    @Component({
    components: { CourseItem, StatusBar, Footer, CoursesList, PurpleButton },
    computed: mapState({
        courses(state: any) {
            return state.courses;
        },
    }),
})
export default class Courses extends Vue {
        private ps = new ProgressService();

    get startImage(): string {
        return this.publicPath + this.$store.state.texts.startImage;
    }

    get startHeading(): string {
        return this.$store.state.texts.startHeading;
    }

    get startText(): string {
        return this.$store.state.texts.startText;
    }


    get startAltText(): string {
        return this.$store.state.texts.startAltText;
    }
        
    get publicPath() {
        return process.env.VUE_APP_CONTENTURL;
    }
    private mounted() {
        this.$ga.page('/home');
  }

}
