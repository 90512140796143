






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component
export default class Chapter extends Vue {
    @Prop() private chapterId!: string;
    @Prop() private course!: any;

    get chapter() {
        const chapter = this.course.Chapters.filter((x: any) => x.Id.toString() === this.chapterId);
        return chapter[0];
    }
}
