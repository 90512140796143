

























import {Component, Vue} from 'vue-property-decorator';
import 'core-js/fn/array/flat-map';
import PurpleButton from '@/components/PurpleButton.vue';

@Component({
    components: {
        PurpleButton,
    },
})

export default class StatusBar extends Vue {
    public goToEhalsomyndigheten() {
        return 'https://ehalsomyndigheten.se';
    }
    get accessibilityLink(): string {
        return this.$store.state.texts.accessibilityLink;
    }
}
