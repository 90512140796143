var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion-border" }, [
    _c(
      "button",
      {
        staticClass: "textmedia-accordion",
        class: { open: _vm.isOpen, closed: !_vm.isOpen },
        on: { click: _vm.toggleAccordion }
      },
      [
        _c(
          "div",
          {
            staticClass: "accordion-head",
            attrs: {
              id: _vm.headingId,
              "aria-expanded": _vm.isOpen ? "true" : "false",
              "aria-haspopup": "true",
              "aria-controls": _vm.contentId,
              role: "button"
            }
          },
          [
            _c("div", { staticClass: "accordion-arrow" }),
            _c("div", {
              staticClass: "accordion-label",
              attrs: {
                "aria-label": _vm.isOpen
                  ? _vm.accordionItem.ShowLessTxt
                  : _vm.accordionItem.ShowMoreTxt
              },
              domProps: {
                textContent: _vm._s(
                  _vm.isOpen
                    ? _vm.accordionItem.ShowLessTxt
                    : _vm.accordionItem.ShowMoreTxt
                )
              }
            })
          ]
        ),
        _c(
          "div",
          {
            ref: "accordionTextCont",
            staticClass: "text-cont",
            attrs: {
              "aria-hidden": _vm.isOpen ? "false" : "true",
              id: _vm.contentId,
              "aria-labelledby": _vm.headingId
            }
          },
          [
            _c("div", {
              ref: "accordionText",
              staticClass: "text accordion-text",
              domProps: { innerHTML: _vm._s(_vm.accordionItem.Text) }
            })
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }