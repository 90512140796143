import { Progress } from '../types/Progress';
import { Course } from '../types/Course';
import { Chapter } from '../types/Chapter';
import { Method } from '../types/Method';
import { CurrentStep } from '@/types/CurrentStep';

export class ProgressService {

    public currentProgress(state: any) {
        return (state.progress.length > 0)
            ? state.progress[state.progress.length - 1]
            : null;
    }

    public nextProgress(state: any): Progress {
        if (this.currentProgress(state) == null) {
            return state.allProgressSteps[0];
        }
        const current = this.currentProgress(state);
        const currentInFlow = state.allProgressSteps.findIndex((p: Progress) => {
            return p.Chapter === current.Chapter
                && p.Method === current.Method;
        });
        if (currentInFlow > -1) {
            if (state.allProgressSteps.length > currentInFlow) {
                return state.allProgressSteps[currentInFlow + 1];
            }
            return state.allProgressSteps[state.allProgressSteps.length - 1];
        }
        return state.allProgressSteps[0];
    }
    /**
     * isUnlockedModule
     */
    public isOpenChapter(state: any, chapter: Chapter): boolean {
        const courses: Course[] = state.courses;
        const progress: Progress[] = state.progress;
        const allProgressSteps: Progress[] = Object.assign([], state.allProgressSteps);
        const nextProgress: Progress = allProgressSteps[progress.length];
        const splicedArr = allProgressSteps.splice(0, progress.length);
        const isUnlocked = splicedArr.some((p) => p.Chapter === chapter.Id) || nextProgress.Chapter === chapter.Id;
        return isUnlocked;
    }

    public isCompletedMethod(state: any, chapter: Chapter, method: Method) {
        return state.progress.some((step: any) => {
            return step.Method === method.Id && step.Chapter === chapter.Id;
        });
    }

    public currentStep(state: any, courseId: number) {
        const currentSteps: CurrentStep[] = state.currentSteps;
        return currentSteps
        .filter((item) => item.Course === courseId)
        .find((currentStep: CurrentStep) => {
            return currentStep;
        });
    }

    public continueCourse(state: any, courseId: number) {
        if (this.currentStep(state, courseId) !== undefined) {
                const courseMethods = this.courseMethods(state, courseId);
                const currentStep = this.currentStep(state, courseId);
                const indexOfCurrentStep = courseMethods.map((e: any) => {
                    return e.Id;
                    }).indexOf(currentStep?.Method);
                if (courseMethods.length > (indexOfCurrentStep + 1)) {
                return true;
            } else {
                return false;
                }
            }
    }

    public isCompletedCourse(state: any) {
        if (state === undefined || !state.hasOwnProperty('allProgressSteps')) {
            return false;
        }
        return state.allProgressSteps.every(
            (step: any) => state.progress.some(
                ((x: any) => x.Method === step.Method)));
    }

    private courseMethods(state: any, courseId: number) {
        const course = state.courses.filter((x: any) => x.Id.toString() === courseId.toString());
        return course.reduce((acc: any, curr: { Chapters: any[]; }) => { const methods = curr.Chapters
                            .reduce((chapAcc: any, chapCurr: { Methods: any; }) =>
                            [...chapAcc, ...chapCurr.Methods], []);
                                                                         return [...acc, ...methods]; }, []);
    }
}
