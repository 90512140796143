var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header", { staticClass: "top-bar-container" }, [_c("status-bar")], 1),
      _c("main", { staticClass: "main" }, [
        _vm.course
          ? _c(
              "div",
              { staticClass: "course" },
              [_c("router-view", { attrs: { course: _vm.course } })],
              1
            )
          : _vm._e()
      ]),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }