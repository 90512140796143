var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "purple-button" },
    [
      _c("router-link", {
        staticClass: "button",
        class: _vm.buttonClass,
        attrs: { tabindex: "", to: { path: _vm.url } },
        domProps: { textContent: _vm._s(_vm.label) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }