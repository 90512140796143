


































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {TextMediaAccordion} from '@/types/Methods/TextMediaAccordion';
import { TextAndMedia } from '@/types/Methods/TextAndMedia';
import MediaAccordionView from '@/components/methods/MediaAccordionView.vue';
import { ContentService } from '../../services/ContentService';

@Component({
    components: {
        MediaAccordionView,
    },
})
export default class ImageTextMethod extends Vue {
    @Prop() private method!: TextAndMedia;
    @Prop() private chapterIndex!: number;
    @Prop() private chapterHeading!: string;
    private cs: ContentService = new ContentService();

    get contentBaseUrl() {
        return process.env.VUE_APP_CONTENTURL;
    }
    get methodHeader() {
        return 'h' + (this.method.HeadingLevel > 0 ? this.method.HeadingLevel : 3);
    }

    private header(item: any) {
        return 'h' + (item.HeadingLevel > 0 ? item.HeadingLevel : 3);
    }

    private getCourse() {
        return this.cs.GetCourseByMethod(this.method);
    }

    private triggerGAEventDownloadPdf() {
        let course = this.getCourse();
        if(course != null) {
            this.$ga.event(`Downloads`, 'click', course.Heading)
        }
    }

    private created() {
        this.$emit('methodComplete');
    }
}
