var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-innercontent-content quit-chapter" }, [
    _c("div", { staticClass: "close-button" }, [
      _c("div", { staticClass: "purple-button" }, [
        _c(
          "button",
          {
            staticClass: "button secondary close",
            attrs: { tabindex: "", "aria-label": "Stäng fönster" },
            on: {
              click: function($event) {
                return _vm.hideModal()
              }
            }
          },
          [_c("b", [_vm._v("+")])]
        )
      ])
    ]),
    _vm._m(0),
    _c("div", { staticClass: "modal-buttons" }, [
      _c("div", { staticClass: "purple-button" }, [
        _c(
          "button",
          {
            staticClass: "button primary cancel arrow",
            attrs: { tabindex: "" },
            on: { click: _vm.cancelModule }
          },
          [_vm._v("Ja, avbryt utbildningen")]
        )
      ]),
      _c("div", { staticClass: "center-link" }, [
        _c(
          "a",
          {
            staticClass: "button",
            attrs: { tabindex: "", href: "javascript:void(0)" },
            on: { click: _vm.hideModal }
          },
          [_vm._v("Nej, fortsätt")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-message" }, [
      _c("h1", [
        _vm._v("Är du säker på att du vill avbryta den pågående utbildningen?")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }