import { Course } from '../types/Course';
import { Chapter } from '../types/Chapter';
import { Method } from '../types/Method';
import store from '../store';

export class ContentService {

    public GetAllChaptersFlat(): Chapter[] {
        return store.state.courses.flatMap((x: Course) => x.Chapters);
    }

    public IndexOfChapter(id: number): number {
        return this.GetAllChaptersFlat().findIndex((m: Chapter) => m.Id === id);
    }

    public GetCourseByMethod(method:Method): Course | null {
        const courses: Course[] = store.state.courses;
        courses.filter((c:Course) => c.Chapters
            .filter((cc: Chapter) => cc.Methods 
            .some((me: Method) => me.Id == method.Id)))
        if(courses.length > 0) {
            return courses[0];
        }
        return null;
    }

    public GetChapterByMethod(method: Method): Chapter | null {
        const chapters: Chapter[] = this.GetAllChaptersFlat()
            .filter((m: Chapter) => m.Methods
                .some((me: Method) => me.Id === method.Id));
        if (chapters.length > 0) {
            return chapters[0];
        }
        return null;
    }

    public IndexOfMethod(method: Method): number {
        const chapter = this.GetChapterByMethod(method);
        if (chapter !== null) {
            return chapter.Methods.findIndex((m: Method) => m.Id === method.Id);
        }
        return -1;
    }

}
