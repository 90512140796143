import Vue from 'vue';
import Vuex from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { Course } from './types/Course';
import { Chapter } from './types/Chapter';
import { Progress } from './types/Progress';
import { CurrentStep } from './types/CurrentStep';

axios.defaults.withCredentials = true;
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        courseTitle: '',
        chapterTitle: '',
        methodTitle: '',
        chapterId:0,
        courses: [],
        appState: {
            modalVisible: false,
            modalComponent: '',
            scrollPos: 0,
            scrollTarget: {},
            noscroll: false,
            timerstarted: false,
        },
        progress: [],
        currentSteps: [],
        allProgressSteps: [],
        texts: {
            startHeading: '',
            startText: '',
            educationDocument: '',
            educationDocumentName: '',
            educationDocumentFileName: '',
            educationElementText: '',
            footerText: '',
            startImage: '',
            startAltText: '',
            startCourseText: '',
            accessibilityLink: ''
        },
    },
    mutations: {
        setCourses(state, coursesData) {
            state.courses = coursesData;
        },
        setProgress(state, progressData) {
            state.progress = progressData;
        },
        setCurrentSteps(state, currentSteps) {
            state.currentSteps = currentSteps;
        },
        setProgressSteps(state) {
            const courses: Course[] = state.courses;
            const steps: any = [];

            courses.forEach((course) => {
                    course.Chapters.forEach((chapter) => {
                        chapter.Methods.forEach((method) => {
                            const step: Progress = {
                                Chapter: chapter.Id,
                                Method: method.Id,
                                Course: course.Id,
                            };
                            steps.push(step);
                        });
                    });
                },
            );
            state.allProgressSteps = steps;
        },
        showModal(state, componentName: string) {
            state.appState.modalVisible = true;
            state.appState.modalComponent = componentName;
        },
        setNoscroll(state, noscroll: boolean) {
            state.appState.noscroll = noscroll;
        },
        hideModal(state) {
            state.appState.modalVisible = false;
        },
        setScrollPos(state, ypos: number) {
            state.appState.scrollPos = ypos;
        },
        setScrollTarget(state, scrollObject: any) {
            state.appState.scrollTarget = scrollObject;
        },
        setTimerStarted(state, started: boolean) {
            state.appState.timerstarted = started;
        },
        setSettings(state, settings) {
            state.texts.startHeading = settings.StartHeading;
            state.texts.startText = settings.StartText;
            state.texts.educationDocument = settings.EducationDocument;
            state.texts.educationDocumentName = settings.EducationDocumentName;
            state.texts.educationDocumentFileName = settings.EducationDocument.split(/(\\|\/)/g).pop();
            state.texts.educationElementText = settings.EducationElementText;
            state.texts.footerText = settings.FooterText;
            state.texts.startImage = settings.StartImage;      
            state.texts.startAltText = settings.StartAltText;
            state.texts.startCourseText = settings.StartCourseText;
            state.texts.accessibilityLink = settings.AccessibilityLink;
        },
        setCourseTitle(state, course: Course) {
            state.courseTitle = course.Title;
        },
        setChapterTitle(state, chapter: Chapter) {
            state.chapterTitle = chapter.Title;
        },
        setMethodTitle(state, method: any) {
            state.methodTitle = method.Title;
        },
        setChapterId(state, chapterId: number) {
            state.chapterId = chapterId;
        }
    },
    actions: {
        getCourses({ commit, dispatch }) {
            axios.get(`${process.env.VUE_APP_API}/getcourses/`)
                .then((res: any) => {
                    commit('setCourses', res.data);
                    commit('setProgressSteps');
                    dispatch('getProgress');
                });
        },

        getCourseTitle({ state, commit }, courseId: number) {
            if(courseId !== undefined) {
                const course = state.courses.find((x: Course) => x.Id === courseId);
                if (course) {
                    commit('setCourseTitle', course);
                }
            }
        },

        getChapterTitle({ state, commit }, { courseId, chapterId }) {
            if (courseId !== undefined) {
                var course: Course | undefined = state.courses.find((x: Course) => x.Id === courseId) as Course | undefined;
                if (course) {
                    const chapter = course.Chapters.find((x: Chapter) => x.Id === chapterId);
                    if (chapter)
                        commit('setChapterTitle', chapter);
                }
            }
        },
        getMethodTitle({ state, commit }, { courseId, chapterId, step }) {
            if (courseId !== undefined) {
                var course: Course | undefined = state.courses.find((x: Course) => x.Id === courseId) as Course | undefined;
                if (course) {
                    const chapter = course.Chapters.find((x: Chapter) => x.Id === chapterId);
                    if (chapter) {
                        const method = chapter.Methods[step-1];
                        console.log(method);
                        if (method)
                            commit('setMethodTitle', method);
                    }
                }
            }
        },

        saveChapterId({ commit }, chapterId: number) {
            if (chapterId) {
                commit('setChapterId', chapterId);
            }
        },

        getSettings({ commit }) {
            axios.get(`${process.env.VUE_APP_API}/getsettings/`)
                .then((res: any) => {
                    commit('setSettings', res.data);
                });
        },
        getProgress({ commit, dispatch, state }) {
            let progress = [];
            if ((localStorage.getItem('progress') !== null)) {
                progress = JSON.parse(localStorage.getItem('progress')!);
            }

            commit('setProgress', progress);
        },

        addProgress({ dispatch, state }, newProgress: Progress) {
            const currentProgress: Progress[] = state.progress;
            if (!currentProgress.includes(newProgress)) {
                currentProgress.push(newProgress);
            }
            localStorage.setItem('progress', JSON.stringify(currentProgress));
            dispatch('getProgress');
        },

        resetCurrentProgress({state, commit}, courseId: number) {
            const currentProgress: Progress[] = state.progress;
            const updatedProgress = currentProgress.filter((item) => item.Course !== courseId);
            commit('setProgress', updatedProgress);
            localStorage.setItem('progress', JSON.stringify(updatedProgress));
        },

        resetChapterProgressAndStep({dispatch}, courseId: number) {
            dispatch('resetCurrentStep', courseId);
            dispatch('resetCurrentProgress', courseId);
        },

        getCurrentSteps({commit}) {
            let currentSteps = [];
            if ((localStorage.getItem('currentSteps') !== null)) {
                currentSteps = JSON.parse(localStorage.getItem('currentSteps')!);
            }
            commit('setCurrentSteps', currentSteps);
        },

        addCurrentCourseStep({dispatch, state}, currentStep: CurrentStep) {
            const currentSteps: CurrentStep[] = state.currentSteps;
            if (currentSteps.some((e) => e.Course === currentStep.Course)) {
                const indexOfStep = currentSteps.map((e) => e.Course).indexOf(currentStep.Course);
                currentSteps.splice(indexOfStep, 1 , currentStep);
            } else {
                currentSteps.push(currentStep);
            }
            localStorage.setItem('currentSteps', JSON.stringify(currentSteps));
            dispatch('getCurrentSteps');
        },

        resetCurrentStep({state, commit}, courseId: number) {
            const currentSteps: CurrentStep[] = state.currentSteps;
            const updatedSteps = currentSteps.filter((item) => item.Course !== courseId);
            commit('setCurrentSteps', updatedSteps);
            localStorage.setItem('currentSteps', JSON.stringify(updatedSteps));
        },
    },
});
