









































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import CheckboxQuestionMethod from '../components/methods/CheckboxQuestionMethod.vue';
import QuickshotQuestionsMethod from '../components/methods/QuickshotQuestionsMethod.vue';
import TextMediaMethod from '../components/methods/TextMediaMethod.vue';
import ChapterFooter from '../components/ChapterFooter.vue';
import ChapterProgress from '../components/ChapterProgress.vue';
import { Course } from '@/types/Course';
import { Chapter } from '@/types/Chapter';
import { ProgressService } from '@/services/ProgressService';
import { ContentService } from '@/services/ContentService';
import StatusBar from '@/components/StatusBar.vue';
import Footer from '@/components/Footer.vue';
import TipsButton from '@/components/TipsButton.vue';
import ChapterList from "@/components/ChapterList.vue";
import ChapterListMobile from "@/components/ChapterListMobile.vue";

@Component({
    components: {
        TipsButton,
        CheckboxQuestionMethod,
        QuickshotQuestionsMethod,
        TextMediaMethod,
        ChapterFooter,
        ChapterProgress,
        StatusBar,
        Footer,
        ChapterList,
        ChapterListMobile,
    },
})
export default class Method extends Vue {

    @Prop() public courseId!: number;
    @Prop() public chapterId!: number;
    @Prop() private step!: number;
    @Prop() private chapter!: Chapter;
    @Prop() private course!: Course;

    private ps: ProgressService = new ProgressService();
    private cs: ContentService = new ContentService();
    private complete: boolean = false;
    private firstComplete: boolean = false;
    // private showTips: boolean = false;

    public selectChapter()
    {
        const chapterSelect = this.$refs.chapterSelect as HTMLSelectElement;
        if(chapterSelect.value.length > 1){

            this.abortCourse();
        }
        else{
            if(chapterSelect.value != '0'){

                this.$router.push('/course/' + this.course.Id + '/chapter/' + this.chapterId + '/' + chapterSelect.value);
            }
        }
    }

    get method() {
        const chapter = this.chapter;
        return chapter.Methods[this.step - 1];
    }
    get totalSteps() {
        return this.chapter.Methods.length;
    }

    get tipsLabel() {
        if (this.method.TipsLabel === '') {
            return 'Visste du att..';
        } else {
            return this.method.TipsLabel;
        }
    }

    get tipstext() {
        return this.method.Tips;
    }
    get chapterIndex() {
        return this.cs.IndexOfChapter(this.chapter.Id);
    }

    private abortCourse() {
        this.$store.commit('showModal', {
            name: 'CancelModuleModal',
            props: {    chapter: this.chapter,
                course: this.course,
            },
        });
    }

    private onComplete() {
        if (this.method.SuccessText !== '') {
            setTimeout(() => {
                this.firstComplete = true;
            }, 1000);
            setTimeout(() => {
                this.complete = true;
            }, 2000);
        } else {
            this.complete = true;
        }
        if (!this.ps.isCompletedMethod(this.$store.state, this.chapter, this.method)) {
            this.$store.dispatch('addProgress', {
                Chapter: this.chapter.Id,
                Method: this.method.Id,
                Course: this.course.Id,
            });
        }
    }

    @Watch('step')
    private onStepChange() {
        this.complete = false;
        this.firstComplete = false;
        // this.showTips = false;
        // Check if method has been completed earlier
        if (!this.ps.isCompletedMethod(this.$store.state, this.chapter, this.method)) {
            this.$store.dispatch('addCurrentCourseStep', {
                Chapter: this.chapter.Id,
                Step: this.step,
                Course: this.course.Id,
                Method: this.method.Id,
            });
        }
    }

    private created() {
        this.onStepChange();
    }
}
