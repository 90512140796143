var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.course.Chapters.length >= 0
    ? _c("div", { staticClass: "course-item" }, [
        _c("div", { staticClass: "img-cont" }, [
          _vm.course.Image
            ? _c("img", {
                attrs: {
                  src: _vm.publicPath + _vm.course.Image,
                  alt: _vm.course.AltText
                }
              })
            : _vm._e()
        ]),
        _c(
          "section",
          { staticClass: "info" },
          [
            _c(_vm.header, {
              tag: "tag",
              domProps: { innerHTML: _vm._s(_vm.course.Heading) }
            }),
            _c("article", { staticClass: "course-text" }, [
              _c("p", {
                staticClass: "small",
                domProps: { innerHTML: _vm._s(_vm.fixHyphens(_vm.course.Text)) }
              })
            ]),
            _c("purple-button", {
              attrs: {
                label: _vm.startCourseText,
                "button-class": "primary forward-button",
                url:
                  "/course/" +
                  _vm.course.Id +
                  "/chapter/" +
                  _vm.course.Chapters[0].Id,
                arrow: true
              },
              nativeOn: {
                click: function($event) {
                  return _vm.triggerGAEventStartCourse()
                }
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "dotted-line" })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }