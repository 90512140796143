var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "method", class: { scrollhide: _vm.firstComplete } },
    [
      _c("chapter-list-mobile", {
        attrs: {
          "current-heading": this.method.Heading,
          courseId: _vm.courseId,
          chapterId: _vm.chapterId,
          course: _vm.course,
          step: _vm.step
        }
      }),
      _c("chapter-progress", {
        attrs: {
          step: _vm.step,
          totalSteps: _vm.totalSteps,
          course: _vm.course,
          chapter: _vm.chapter
        }
      }),
      _c("div", { staticClass: "method-container-outer" }, [
        _c("div", { staticClass: "method-container-inner" }, [
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "method-container left-column" },
              [
                _c(
                  "div",
                  { staticClass: "method-content", attrs: { id: "content" } },
                  [
                    _vm.method.Type === "TextMediaMethod"
                      ? _c("TextMediaMethod", {
                          key: _vm.method.Id,
                          attrs: {
                            method: _vm.method,
                            complete: _vm.complete,
                            chapterIndex: _vm.chapterIndex,
                            chaptereading: _vm.chapter.Heading
                          },
                          on: { methodComplete: _vm.onComplete }
                        })
                      : _vm._e(),
                    _vm.method.Type === "Checkbox"
                      ? _c("CheckboxQuestionMethod", {
                          key: _vm.method.Id,
                          attrs: { method: _vm.method, complete: _vm.complete },
                          on: { methodComplete: _vm.onComplete }
                        })
                      : _vm._e(),
                    _vm.method.Type === "CheckboxQuestion"
                      ? _c("CheckboxQuestionMethod", {
                          key: _vm.method.Id,
                          attrs: { method: _vm.method, complete: _vm.complete },
                          on: { methodComplete: _vm.onComplete }
                        })
                      : _vm._e(),
                    _vm.method.Type === "ChooseRight"
                      ? _c("CheckboxQuestionMethod", {
                          key: _vm.method.Id,
                          attrs: { method: _vm.method, complete: _vm.complete },
                          on: { methodComplete: _vm.onComplete }
                        })
                      : _vm._e(),
                    _vm.method.Type === "QuickshotQuestions"
                      ? _c("QuickshotQuestionsMethod", {
                          key: _vm.method.Id,
                          attrs: { method: _vm.method, complete: _vm.complete },
                          on: { methodComplete: _vm.onComplete }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("ChapterFooter", {
                  attrs: {
                    pagetype: "method",
                    complete: _vm.complete,
                    step: _vm.step,
                    chapter: _vm.chapter,
                    tips: _vm.tipstext,
                    course: _vm.course,
                    method: _vm.method
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "right-column" },
              [
                _c("chapter-list", {
                  attrs: {
                    step: _vm.step,
                    totalSteps: _vm.totalSteps,
                    course: _vm.course,
                    selectedChapterId: _vm.chapterId ? _vm.chapterId : 0
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }