











import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import CourseItem from '../components/CourseItem.vue';
import StatusBar from '../components/StatusBar.vue';

@Component({
    name: 'CoursesList',
    components: { CourseItem, StatusBar },
    computed: mapState({
        courses(state: any) {
            return state.courses;
        },
    }),
})
export default class CoursesList extends Vue {

}
