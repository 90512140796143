














import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import 'core-js/fn/array/flat-map';
import PurpleButton from '@/components/PurpleButton.vue';
import { Fixhyphens } from '@/helpers/Helpers';

@Component({
    components: {
    },
})

export default class Footer extends Vue {
    private showshadow: boolean = false;
    private fixHyphens = Fixhyphens;

    @Watch('$route')
    private onRouteChanged(value: Route) {
        this.showshadow = value.name === 'method';
    }

    get footerText(): string {
        return this.$store.state.texts.footerText;
    }
}
