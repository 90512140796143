
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CancelModuleModal extends Vue {
    @Prop() private props!: any;
    public hideModal() {
        this.$store.commit('hideModal');
    }
    public cancelModule() {
        this.$store.commit('hideModal');
        this.$router.push(`/course/${this.props.course.Id}/chapter/${this.props.chapter.Id}`);
        this.$ga.event(`Course ${this.props.course.Heading}`, 'click', `End Chapter ${this.props.chapter.Heading}`)
    }
}
