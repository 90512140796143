

















































































import { Component, Prop, Vue } from 'vue-property-decorator';
    import PurpleButton from '@/components/PurpleButton.vue';
    import { Fixhyphens } from '@/helpers/Helpers';

@Component( {
    components: { PurpleButton },
})
export default class QuickshotQuestionsMethod extends Vue {

    @Prop() private method!: any;
    @Prop() private complete!: boolean;

    private fixHyphens = Fixhyphens;
    private questionsCompleted = 0;
    private incorrect: any = [-1, -1];
    private animate: any = [-1, -1];

    get header() {
        return 'h' + (this.method.HeadingLevel > 0 ? this.method.HeadingLevel : 3);
    }

    private answerQuestion(q: number, alternative: number) {

        this.method.Questions[q].FirstSelected = (alternative === 1);
        this.method.Questions[q].SecondSelected = (alternative === 2);

        Vue.set(this.method.Questions[q], 'Checked', true);
        if ((alternative === 1 && this.method.Questions[q].FirstAlternativeCorrect)
            || (alternative === 2 && !this.method.Questions[q].FirstAlternativeCorrect)) {
            this.animate = [q, alternative];
            setTimeout(() => {
                this.animate = [-1, -1];
            }, 500);
        } else {
            this.incorrect = [q, alternative];
            this.animate = [q, alternative];
            setTimeout(() => {
                this.animate = [-1, -1];
            }, 500);
        }
        if (q >= this.questionsCompleted) {
            this.questionsCompleted++;
        }
        if (this.questionsCompleted === this.method.Questions.length) {
            setTimeout(() => {
                this.$emit('methodComplete');
            }, 1000);
        }
        /*
        setTimeout(() => {
            this.$scrollTo('#scrollbottom', 400);
             window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth'});
        }, 300);
         */
    }
}
