

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class TipsButton extends Vue {
    @Prop() private tipstext!: string;
    @Prop() private tipsheading!: string;
    @Prop() private tipsLabel!: string;

    private isVisible: boolean = false;
    private animate: boolean = false;

    /* TODO */
    @Watch('$route')
    private closeOnRouteChange(): void {
        this.isVisible = false;
    }

    private toggleTips() {
        this.animate = true;
        this.isVisible = !this.isVisible;
        const instance = this;
        setTimeout(
            () => {
                instance.animate = false; },
        500);
    }
}
