

















import { Component, Prop, Vue } from 'vue-property-decorator';
import {TextMediaAccordion} from '@/types/Methods/TextMediaAccordion';

@Component({ })

export default class MediaAccordionView extends Vue {
    @Prop() private accordionItem!: TextMediaAccordion;
    private isOpen: boolean = false;
    private contentId: string = `text-${this.accordionItem.ShortGuid}`;
    private headingId: string = `heading-${this.accordionItem.ShortGuid}`;


    private toggleAccordion() {
        const accordionText = this.$refs.accordionText as HTMLElement;
        const accordionTextCont = this.$refs.accordionTextCont as HTMLElement;

        if (this.isOpen) {
            accordionTextCont.style.height = '0';
        } else {
            accordionTextCont.style.height = accordionText.scrollHeight + 'px';    
        }

        this.isOpen = !this.isOpen;
    }
}
