






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {Course} from '../types/Course';
import ProgressBar from "@/components/ProgressBar.vue";

@Component({
    components: {ProgressBar},
})

export default class ChapterList extends Vue {
    @Prop() private step!: number;
    @Prop() public courseId!: number;
    @Prop() public chapterId!: number;
    @Prop() private course!: Course;
    @Prop() private currentHeading!: string;
    @Prop() private selectedChapterId!: number;
    public header: string = '';
    public displaySelectMenu: boolean = false;

    public toggleSelect()
    {
        this.displaySelectMenu = !this.displaySelectMenu;
    }


    public select(clickTxt:string, clickedId:number, clickedStep:number)
    {
        this.toggleSelect();
        console.log("index: " + clickedStep);
        console.log("steg: " + this.step);
        
        if(clickedStep == 0){
            this.$router.push('/course/' + this.course.Id + '/chapter/' + clickedId);
        }
        else{

            this.$router.push('/course/' + this.course.Id + '/chapter/' + clickedId+ '/' +clickedStep);
        }


    }

    get realChapterId() {
        if (this.chapterId) {
            return this.chapterId;
        }
        else {
            return this.course.Chapters[0].Id;
        }
    }

    get chapter() {
        for (let i = 0, j = this.course.Chapters.length; i < j; i++) {
            if (this.course.Chapters[i].Id == this.realChapterId) {
                this.header = 'h' + (this.course.Chapters[i].HeadingLevel > 0 ? this.course.Chapters[i].HeadingLevel : 3);
                return this.course.Chapters[i];
            }
        }
        return false;
    }
}
