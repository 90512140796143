














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {Course} from '@/types/Course';
import {Chapter} from "@/types/Chapter";

@Component({
    components: {},
})

export default class ChapterList extends Vue {
    @Prop() private chapter!: Chapter;
    @Prop() private course!: Course;
    
    get currentChapter() {
        let currentChapter = 0
        
        for (let  i = 0; i < this.course.Chapters.length; i++){
            
            if(this.course.Chapters[i].Heading === this.chapter.Heading){
                currentChapter = i;
            }
        }
        return currentChapter;
    }
    
}
