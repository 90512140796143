var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "textmedia method-module" }, [
    _c(
      "div",
      { staticClass: "textmedia-container method-module-container" },
      [
        _vm.method.Heading
          ? _c(_vm.methodHeader, {
              tag: "tag",
              staticClass: "method-heading",
              domProps: { textContent: _vm._s(_vm.method.Heading) }
            })
          : _vm._e(),
        _vm.method.Text
          ? _c("div", {
              staticClass: "textmedia-intro method-intro",
              domProps: { innerHTML: _vm._s(_vm.method.Text) }
            })
          : _vm._e(),
        _vm.method.TextMediaList
          ? _c(
              "div",
              { staticClass: "textmedia-list" },
              _vm._l(_vm.method.TextMediaList, function(item, index) {
                return _c("div", { key: index }, [
                  item.Type == "DividerGrey"
                    ? _c("div", [_c("hr", { staticClass: "grey-divider" })])
                    : _vm._e(),
                  item.Type == "DividerYellow"
                    ? _c("div", [_c("hr", { staticClass: "yellow-divider" })])
                    : _vm._e(),
                  item.Type == "MediaImage"
                    ? _c("div", { staticClass: "textmedia-list-item" }, [
                        item.Url !== ""
                          ? _c(
                              "div",
                              { staticClass: "textmedia-image image" },
                              [
                                _c("div", { staticClass: "image-cont" }, [
                                  _c("img", {
                                    ref: "image",
                                    refInFor: true,
                                    attrs: {
                                      src: "" + _vm.contentBaseUrl + item.Url,
                                      alt: item.AltText
                                    }
                                  }),
                                  item.Text
                                    ? _c(
                                        "div",
                                        {
                                          ref: "content",
                                          refInFor: true,
                                          staticClass: "imagetext"
                                        },
                                        [_vm._v(_vm._s(item.Text))]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  item.Type === "MediaText"
                    ? _c(
                        "div",
                        { staticClass: "textmedia-list-item textmedia-text" },
                        [
                          item.Heading
                            ? _c(_vm.header(item), {
                                tag: "tag",
                                staticClass: "heading",
                                domProps: { textContent: _vm._s(item.Heading) }
                              })
                            : _vm._e(),
                          item.Text
                            ? _c("div", {
                                staticClass: "text",
                                domProps: { innerHTML: _vm._s(item.Text) }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Type === "MediaAccordion" &&
                  item.Text !== "" &&
                  item.Text !== undefined
                    ? _c(
                        "div",
                        { staticClass: "textmedia-list-item" },
                        [
                          _c("media-accordion-view", {
                            attrs: { accordionItem: item }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  item.Type == "MediaVideo"
                    ? _c("div", { staticClass: "textmedia-list-item" }, [
                        item.Url !== ""
                          ? _c(
                              "div",
                              { staticClass: "textmedia-video video" },
                              [
                                _c(
                                  "video",
                                  {
                                    ref: "player",
                                    refInFor: true,
                                    attrs: { controls: "", title: item.AltText }
                                  },
                                  [
                                    _c("source", {
                                      attrs: {
                                        src: "" + _vm.contentBaseUrl + item.Url,
                                        type: "video/mp4"
                                      }
                                    })
                                  ]
                                ),
                                item.SignVideo || item.SightVideo
                                  ? _c(
                                      "div",
                                      { staticClass: "video-accessible" },
                                      [
                                        _c("p", [
                                          _c("b", [_vm._v("Se med: ")]),
                                          _c("span", [
                                            item.SignVideo
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "video-accessible-link",
                                                    attrs: {
                                                      href: item.SignVideo,
                                                      target: "_blank",
                                                      rel: "noopener noreferrer"
                                                    }
                                                  },
                                                  [_vm._v("Teckenspråkstolk")]
                                                )
                                              : _vm._e(),
                                            item.SightVideo
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "video-accessible-link",
                                                    attrs: {
                                                      href: item.SightVideo,
                                                      target: "_blank",
                                                      rel: "noopener noreferrer"
                                                    }
                                                  },
                                                  [_vm._v("Syntolk")]
                                                )
                                              : _vm._e()
                                          ])
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "videotext" }, [
                                  _vm._v(_vm._s(item.Text))
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  item.Type == "MediaVideoExternal"
                    ? _c("div", { staticClass: "textmedia-list-item" }, [
                        item.Url !== ""
                          ? _c(
                              "div",
                              { staticClass: "textmedia-video video" },
                              [
                                _c("div", { staticClass: "video-primary" }, [
                                  _c("iframe", {
                                    attrs: {
                                      src:
                                        "https://www.youtube.com/embed/" +
                                        item.Url +
                                        "?rel=0",
                                      allowfullscreen: "",
                                      title: item.AltText
                                    }
                                  })
                                ]),
                                item.SignVideo || item.SightVideo
                                  ? _c(
                                      "div",
                                      { staticClass: "video-accessible" },
                                      [
                                        _c("p", [
                                          _c("b", [_vm._v("Se med: ")]),
                                          _c("span", [
                                            item.SignVideo
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "video-accessible-link",
                                                    attrs: {
                                                      href: item.SignVideo,
                                                      target: "_blank",
                                                      rel: "noopener noreferrer"
                                                    }
                                                  },
                                                  [_vm._v("Teckenspråkstolk")]
                                                )
                                              : _vm._e(),
                                            item.SightVideo
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "video-accessible-link",
                                                    attrs: {
                                                      href: item.SightVideo,
                                                      target: "_blank",
                                                      rel: "noopener noreferrer"
                                                    }
                                                  },
                                                  [_vm._v("Syntolk")]
                                                )
                                              : _vm._e()
                                          ])
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "videotext" }, [
                                  _vm._v(_vm._s(item.Text))
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  item.Type === "MediaPdf"
                    ? _c("div", { staticClass: "elearning-info" }, [
                        _c("div", { staticClass: "document-box" }, [
                          _c(
                            "div",
                            { staticClass: "document-box-inner" },
                            [
                              item.Heading
                                ? _c(_vm.header(item), {
                                    tag: "tag",
                                    domProps: {
                                      textContent: _vm._s(item.Heading)
                                    }
                                  })
                                : _vm._e(),
                              item.PdfFile
                                ? _c("p", {
                                    domProps: {
                                      textContent: _vm._s(item.PdfText)
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "" + _vm.contentBaseUrl + item.PdfFile,
                                    target: "_new"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.triggerGAEventDownloadPdf()
                                    }
                                  }
                                },
                                [
                                  _vm._v("Diplom"),
                                  _c("br"),
                                  _vm._v(" (PDF, öppnas i nytt fönster)")
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              }),
              0
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }