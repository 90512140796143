














import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { Method } from '../types/Method';
import StatusBar from '../components/StatusBar.vue';
import Footer from '../components/Footer.vue';
import { ProgressService } from '@/services/ProgressService';

@Component({
    components: { StatusBar, Footer },
})
export default class Course extends Vue {
    @Prop() public courseId!: number;
    private currentStep!: any | undefined;
    private ps: ProgressService = new ProgressService();

    get course() {
        const course = this.$store.state.courses.filter((x: any) => x.Id.toString() === this.courseId.toString());
        if (course.length > 0) {
            return course[0];
        }
        return false;
    }

    private showModal() {
            if (this.ps.continueCourse(this.$store.state, this.course.Id)) {
                this.$store.commit('showModal', {
                    name: 'ContinueOrResetModal',
                    props: {
                        courseId: this.course.Id,
                    },
                });
                } else if (!this.ps.continueCourse(this.$store.state, this.course.Id)) {
                        this.$store.dispatch('resetChapterProgressAndStep', this.course.Id);
                }
    }

    private created() {
        this.showModal();

        /*
        if (this.$store.state.course.length < 1) {
            this.$router.push('/');
        }
         */
    }
}
