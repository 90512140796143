var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal", attrs: { role: "alert" } },
    [
      _c("transition", { attrs: { name: "overlay-animation" } }, [
        _vm.visible ? _c("div", { staticClass: "modal-overlay" }) : _vm._e()
      ]),
      _c("transition", { attrs: { name: "modal-animation" } }, [
        _vm.visible
          ? _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "modal-innercontent",
                  class: { large: _vm.isLarge, hasclose: _vm.hasClose }
                },
                [
                  _c(_vm.innerComponent, {
                    tag: "component",
                    attrs: { props: _vm.props }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }