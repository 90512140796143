var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progressbar right-column" },
    _vm._l(_vm.course.Chapters, function(chapterObj, index) {
      return _c(
        "div",
        {
          key: chapterObj.Id,
          staticClass: "progress-item",
          class: { "is-current-chapter": index <= _vm.currentChapter },
          style: {
            width:
              index > 0
                ? (1 /
                    (_vm.course.Chapters.length > 0
                      ? _vm.course.Chapters.length - 1
                      : 1)) *
                    100 +
                  "%"
                : "auto"
          }
        },
        [_c("p", [_vm._v(_vm._s(index + 1))])]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }