




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {Course} from '../types/Course';

@Component({
    components: {},
})

export default class ChapterList extends Vue {
    @Prop() private course!: Course;
    @Prop() private selectedChapterId!: number;
    @Prop() private step!: string;
    @Prop() private totalSteps!: number;
}
