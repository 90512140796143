










































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Chapter} from '@/types/Chapter';
import {Course} from '@/types/Course';
import ProgressBar from "@/components/ProgressBar.vue";
@Component({
    components: {ProgressBar}
})
export default class ChapterProgress extends Vue {
    @Prop() private step!: string;
    @Prop() private totalSteps!: number;
    @Prop() private chapter!: Chapter;
    @Prop() private course!: Course;
    



}
