











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PurpleButton extends Vue {

    @Prop() public clickAction!: CallableFunction;
    @Prop() public arrow!: boolean;
    @Prop() private label!: string;
    @Prop() private buttonType!: string; // 'primary' / 'secondary';
    private buttonClass: string; // 'primary' / 'secondary';
    @Prop() private url!: string;

    constructor() {
        super();
        this.buttonClass = (this.buttonType === 'secondary') ? 'secondary' : 'primary';
        if (this.arrow) {
            this.buttonClass += ' arrow';
        }
    }    private onClick(): void {
        if (this.url) {
            window.location.href = this.url;
        }
        if (this.clickAction) {
           this.clickAction();
        }
    }
}
