import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Chapter from './views/Chapter.vue';
import Course from './views/Course.vue';
import CourseInfo from './views/CourseInfo.vue';
import Method from './views/Method.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
                // , offset: { x: 0, y: 10 }
            }
        }
    },
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/course/:courseId',
            name: 'course',
            component: Course,
            props: true,
            children: [
                {
                    path: '',
                    name: 'courseInfo',
                    component: CourseInfo,
                },
                {
                    path: 'chapter/:chapterId/',
                    props: true,
                    component: Chapter,
                    children: [
                        {
                            name: 'chapter',
                            path: '',
                            component: CourseInfo,
                            props: true,
                        },
                        {
                            path: ':step',
                            name: 'method',
                            component: Method,
                            props: true,
                        },
                    ],
                },
            ],
        },
    ],
});
