








    import { mapState } from 'vuex';
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import Modal from './components/Modal.vue';
    import Footer from "@/components/Footer.vue";

    @Component({
        components: {Footer, Modal },
        computed: mapState(['courseTitle', 'chapterTitle', 'methodTitle', 'chapterId']),
    })
    export default class App extends Vue {
        private courseTitle!: string;
        private chapterTitle!: string;
        private methodTitle!: string;
        private chapterId!: number;
        private baseTitle: string = "Webbutbildning NLL E-hälsomyndigheten";

/*        @Watch('scrollTarget')
        private scrollTo(newTarget: any) {
            this.$nextTick(function () {
                this.$scrollTo(newTarget.element, newTarget.duration, newTarget.options);
            });
        }*/

        @Watch('$route')
        private onRouteChange(from: any, to: any) {
            window.scrollTo(0, 0);
        }

        private get noscroll() {
            return this.$store.state.appState.noscroll;
        }

        @Watch('noscroll')
        private onNoScrollChange(newval: any, oldval: any) {
            const body = document.getElementsByTagName('body')[0];
            const modal = body.querySelector('.modal');
            newval ? body.classList.add('noscroll') : body.classList.remove('noscroll');
            
            
            const allLinks =  body.getElementsByTagName("a");
            for (let i = 0; i < allLinks.length; i++) {
                newval ? allLinks[i].setAttribute("tabindex", "-1") : allLinks[i].setAttribute("tabindex", "");
            }
            
            const allButtons =  body.getElementsByTagName("button");
            for (let i = 0; i < allButtons.length; i++) {
                newval ? allButtons[i].setAttribute("tabindex", "-1") : allButtons[i].setAttribute("tabindex", "");
            }

            const allIframes =  body.getElementsByTagName("iframe");
            for (let i = 0; i < allIframes.length; i++) {
                newval ? allIframes[i].setAttribute("tabindex", "-1") : allIframes[i].setAttribute("tabindex", "");
            }
            
            const allVideos =  body.getElementsByTagName("video");
            for (let i = 0; i < allVideos.length; i++) {
                newval ? allVideos[i].setAttribute("tabindex", "-1") : allVideos[i].setAttribute("tabindex", "");
            }
            
            if(modal){
                newval ? modal.setAttribute("focusable", "true"): modal.setAttribute("focusable", "false");
            }
            
        }

        private bootstrap(): void {
            this.$store.dispatch('getCourses');
            this.$store.dispatch('getSettings');
            this.$store.commit('setProgressSteps');
            this.$store.dispatch('getProgress');
            this.$store.dispatch('getCurrentSteps');
            this.setDocumentTitle();
            //this.scrollTo(this.$route.hash);
        }

        @Watch('$route')
        private setDocumentTitle(): void {
            this.$router.beforeEach((to, from, next) => {
                let title = this.baseTitle;
                console.log("to name: " + to.name)
                switch (to.name) {
                    case "course": {
                        this.$store.dispatch('getCourseTitle', to.params["courseId"]);
                        if (this.courseTitle !== "")
                            title = this.courseTitle + ' - ' + title;
                        break;
                    }
                    case "courseInfo": {
                        this.$store.dispatch('getChapterTitle', { courseId: parseInt(to.params["courseId"]), chapterId: this.chapterId })
                        if (this.chapterTitle !== "") {
                            title = this.chapterTitle + ' - ' + title;
                            console.log("courseInfo chapterTitle: " + title)
                        }
                        else {
                            this.$store.dispatch('getCourseTitle', to.params["courseId"]);
                            console.log("courseInfo courseTitle: " + this.courseTitle)
                            if (this.courseTitle !== "") {
                                title = this.courseTitle + ' - ' + title;
                                console.log("courseInfo courseTitle: " + title)
                            }
                        }
                        break;
                    }
                    case "chapter":
                        {
                            this.$store.dispatch('getChapterTitle', { courseId: parseInt(to.params["courseId"]), chapterId: parseInt(to.params["chapterId"]) })
                            if (this.chapterTitle !== "")
                                title = this.chapterTitle + ' - ' + title;
                            break;
                        }
                    case "method": {
                        this.$store.dispatch('getMethodTitle', { courseId: parseInt(to.params["courseId"]), chapterId: parseInt(to.params["chapterId"]), step: parseInt(to.params["step"]) })
                        if (this.methodTitle !== "")
                            title = this.methodTitle + ' - ' + title;
                        break;
                    }
                    default: {
                        break;
                    }
                }

                if (title !== "")
                    document.title = `${title}`;

                next();
            });
        }

        get scrollTarget(): any {
            return this.$store.state.appState.scrollTarget;
        }
        private created() {
            this.bootstrap();
        }
    }
