var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chapter-footer" }, [
    _c("div", { staticClass: "chapter-footer-inner buttons " }, [
      _vm.pagetype === "method"
        ? _c(
            "div",
            { staticClass: "purple-button" },
            [
              _c("router-link", {
                ref: "backButton",
                staticClass: "button secondary back back-button",
                attrs: { to: this.previousStepUrl, tabindex: "" },
                domProps: { textContent: _vm._s("Bakåt") },
                on: { click: _vm.clickBack }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.pagetype === "method"
        ? _c(
            "div",
            { staticClass: "purple-button" },
            [
              _c("router-link", {
                ref: "forwardButton",
                staticClass: "button forward primary arrow",
                attrs: { to: this.nextStepUrl, tabindex: "" },
                domProps: { textContent: _vm._s(_vm.forwardButtonLabel) },
                on: { click: _vm.clickForward }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }