


















import { Component, Prop, Vue} from 'vue-property-decorator';
import PurpleButton from '@/components/PurpleButton.vue';
    import { Course } from '@/types/Course';
    import { Fixhyphens } from '@/helpers/Helpers';

@Component({
    components: {
        PurpleButton,
    },
})
export default class CourseItem extends Vue {
    @Prop() private course!: Course;
    @Prop() private courseIndex!: number;
    @Prop() private nextProgress!: number;

    private fixHyphens = Fixhyphens;
    get startCourseText(): string {
        if(this.$store.state.texts.startCourseText && this.$store.state.texts.startCourseText !== '')
            return this.$store.state.texts.startCourseText;
        return 'Starta utbildning';
    }

    public triggerGAEventStartCourse() {
        this.$ga.event(`Course ${this.course.Heading}`, 'click', 'Start course')
    }

    get publicPath() {
        return process.env.VUE_APP_CONTENTURL;
    }
    get header() {
        return 'h' + (this.course.HeadingLevel > 0 ? this.course.HeadingLevel : 3);
    }
}
