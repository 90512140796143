




























import { Component, Prop, Vue} from 'vue-property-decorator';
import ChapterProgress from './ChapterProgress.vue';
import {Method} from '@/types/Method';
import {Course} from '@/types/Course';
import {Chapter} from '@/types/Chapter';

@Component({
    components: {
        ChapterProgress,
    },
})
export default class ChapterFooter extends Vue {

    @Prop() private complete!: boolean;
    @Prop() private step!: string;
    @Prop() private chapter!: Chapter;
    @Prop() private course!: Course;
    @Prop() private help!: string;
    @Prop() private tips!: string;
    @Prop() private pagetype!: string;
    @Prop() private method!: Method;

    get methodpath() {
        return this.pagetype === 'chapter' ? './1' : '/courses';
    }

    private clickForward() {
        this.$router.push(this.nextStepUrl);
        const forwardButton = this.$refs.forwardButton as HTMLElement;
        forwardButton.blur();
    }

    private clickBack() {
        this.$router.push(this.previousStepUrl);
        const backButton = this.$refs.backButton as HTMLElement;
        backButton.blur();
    }

    get forwardButtonLabel() {
        if (this.method.ForwardButtonLabel === '') {
        return 'Gå vidare';
        } else {
        return this.method.ForwardButtonLabel;
        }
    }

    get isLastChapter() {
        return (this.nextChapterId < 0);
    }

    get nextStepUrl() {
        // last step ?
        if (parseInt(this.step, 10) === this.totalSteps) {
            if (this.isLastChapter) {
                return '/';
            } else {
                return ('/course/' + this.course.Id + '/chapter/' + this.nextChapterId);
            }
        } else {
            const nextStep = (parseInt(this.step, 10) + 1);
            return `./${nextStep}`;
        }
    }


    get nextChapterId() {
        // find next chapter
        let chapterFound = false;
        let nChapId = -1;

        for (let i = 0, j = this.course.Chapters.length; i < j; i++) {
            if (chapterFound) {
                if (this.course.Chapters[i]) {
                    if (this.course.Chapters[i].Methods.length > 0) {
                        nChapId = this.course.Chapters[i].Id;
                        break;
                    }
                }
            }
            if (this.course.Chapters[i].Id === this.chapter.Id) {
                chapterFound = true;
            }
        }

        return nChapId;
    }


    get previousStepUrl() {
        const previousStep = parseInt(this.step, 10) - 1;
        if (parseInt(this.step, 10) <= 1) {
            return './';
        }
        return './' + previousStep;
    }
    get totalSteps() {
        return this.chapter.Methods.length;
    }
    get currentMethod() {
        return this.chapter.Methods[parseInt(this.step, 10) - 1];
    }
}
