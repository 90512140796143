
import store from '../store';

export const Shuffle = (arr: any) => {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
};

export const Fixhyphens = (text: string) => {
    return text.replace(/(\S+)-(\S+)/g, '$1&#8288;-&#8288;$2');
}
