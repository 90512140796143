var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "chapter-progress", attrs: { "aria-label": "Breadcrumb" } },
    [
      _c(
        "div",
        { staticClass: "chapter-inner columns" },
        [
          _c(
            "div",
            { staticClass: "breadcrumbs left-column" },
            [
              _c(
                "router-link",
                {
                  staticClass: "start breadcrumb",
                  attrs: { to: "/", tag: "a" },
                  domProps: { textContent: _vm._s("Startsidan") }
                },
                [_vm._v("Startsidan")]
              ),
              _vm.course
                ? _c("div", { staticClass: "divider" }, [_vm._v(" / ")])
                : _vm._e(),
              _vm.course && _vm.chapter
                ? _c("router-link", {
                    staticClass: "course breadcrumb",
                    attrs: { to: "/course/" + _vm.course.Id },
                    domProps: { textContent: _vm._s(_vm.course.Heading) }
                  })
                : _vm.course
                ? _c("div", {
                    staticClass: "course breadcrumb",
                    domProps: { textContent: _vm._s(_vm.course.Heading) }
                  })
                : _vm._e(),
              _vm.chapter
                ? _c("div", { staticClass: "divider" }, [_vm._v(" / ")])
                : _vm._e(),
              _vm.chapter && _vm.step
                ? _c("router-link", {
                    staticClass: "chapter breadcrumb",
                    attrs: {
                      to:
                        "/course/" +
                        _vm.course.Id +
                        "/chapter/" +
                        _vm.chapter.Id
                    },
                    domProps: { textContent: _vm._s(_vm.chapter.Heading) }
                  })
                : _c("div", {
                    staticClass: "chapter breadcrumb",
                    domProps: { textContent: _vm._s(_vm.chapter.Heading) }
                  })
            ],
            1
          ),
          _c("progress-bar", {
            attrs: { course: _vm.course, chapter: _vm.chapter }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }