var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "courses-list" },
    _vm._l(_vm.courses, function(course, index) {
      return _c("course-item", {
        key: course.Id,
        style: 2,
        attrs: { course: course, courseIndex: index }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }