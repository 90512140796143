
















import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { mapState, mapMutations, createNamespacedHelpers } from 'vuex';
import CancelModuleModal from './modals/CancelModuleModal.vue';
import ContinueOrResetModal from './modals/ContinueOrResetModal.vue';

@Component({
    components: { CancelModuleModal, ContinueOrResetModal },
    computed: {
        visible() {
            return this.$store.state.appState.modalVisible;
        },
        modalComponent() {
            return this.$store.state.appState.modalComponent;
        },
    },
    data() {
        return {
            innerComponent: null,
        };
    },
})
export default class Modal extends Vue {

    public visible!: boolean;
    public innerComponent!: any;
    public props: any = { size: '', hasClose: false };

    @Watch('visible')
    public onVisibleChanged(isVisible: boolean, oldVal: boolean) {
        this.$store.commit('setNoscroll', isVisible);
    }

    @Watch('modalComponent')
    public onComponentChanged(componentObj: any, oldVal: any) {
        if (!componentObj) { return; }
        switch (componentObj.name) {
            case 'CancelModuleModal':
                Vue.component(componentObj.name, CancelModuleModal);
                break;
            case 'ContinueOrResetModal':
                Vue.component(componentObj.name, ContinueOrResetModal);
                break;
        }
        this.innerComponent = componentObj.name;
        this.props = componentObj.props;
    }
    get isLarge(): boolean {
        return this.props && this.props.size && this.props.size === 'l';
    }
    get hasClose(): boolean {
        return this.props && this.props.hasClose;
    }
    public hideModal() {
        this.$store.commit('hideModal');
    }
    public created() {
        const escapeHandler: any = (e: any) => {
            if (e.key === 'Escape' && this.visible) {
                this.hideModal();
            }
        };
        document.addEventListener('keydown', escapeHandler);
        this.$once('hook:destroyed', () => {
            document.removeEventListener('keydown', escapeHandler);
        });
    }
}
