var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "status-bar", attrs: { role: "banner" } }, [
    _c("div", { staticClass: "status-bar-container" }, [
      _c("div", { staticClass: "status-bar-inner" }, [
        _c("div", { staticClass: "top-menu" }, [
          _c("div", { staticClass: "top-menu-inner" }),
          _c("div", { staticClass: "buttons" }, [
            _c(
              "a",
              {
                staticClass: "skip-to-content primary forward-button",
                attrs: { href: "#content" }
              },
              [_vm._v("Gå till huvudinnehåll")]
            ),
            _c(
              "a",
              {
                staticClass: "accessibility-link",
                attrs: { target: "_blank", href: _vm.accessibilityLink }
              },
              [_vm._v("Tillgänglighet")]
            )
          ])
        ]),
        _c("div", { staticClass: "bottom-menu" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "router-link",
                { staticClass: "purple-button", attrs: { to: { path: "/" } } },
                [
                  _c("img", {
                    staticClass: "logo",
                    attrs: {
                      src: require("../assets/logo-purple.svg"),
                      alt: "E-hälsomyndigheten",
                      role: "img",
                      "aria-label": "home"
                    }
                  })
                ]
              ),
              _c("div", { staticClass: "purple-button webpage-btn" }, [
                _c(
                  "a",
                  {
                    staticClass: "button arrow",
                    attrs: { href: _vm.goToEhalsomyndigheten() }
                  },
                  [_vm._v("Till ehälsomyndigheten.se")]
                )
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }