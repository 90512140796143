var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chapter-list" },
    [
      _c("h2", { staticClass: "label" }, [_vm._v("Utbildningens kapitel")]),
      _vm._l(_vm.course.Chapters, function(chapter, index) {
        return _c("div", { key: chapter.Id, staticClass: "chapters" }, [
          chapter.Methods.length > 0
            ? _c(
                "div",
                { staticClass: "chapter-items" },
                [
                  !_vm.totalSteps &&
                  (chapter.Id == _vm.selectedChapterId ||
                    _vm.selectedChapterId == index)
                    ? _c("div", { staticClass: "chapter-arrow" })
                    : _vm._e(),
                  _c("router-link", {
                    attrs: {
                      to: "/course/" + _vm.course.Id + "/chapter/" + chapter.Id
                    },
                    domProps: {
                      textContent: _vm._s(
                        chapter.Heading !== "" && chapter.Heading !== undefined
                          ? chapter.Heading
                          : "Kapitel " + (index + 1)
                      )
                    }
                  }),
                  _vm._l(chapter.Methods, function(method, index2) {
                    return chapter.Id == _vm.selectedChapterId
                      ? _c(
                          "div",
                          { staticClass: "method-items" },
                          [
                            chapter.Id == _vm.selectedChapterId &&
                            _vm.step == index2 + 1
                              ? _c("div", { staticClass: "chapter-arrow" })
                              : _vm._e(),
                            _c("router-link", {
                              attrs: {
                                to:
                                  "/course/" +
                                  _vm.course.Id +
                                  "/chapter/" +
                                  chapter.Id +
                                  "/" +
                                  (index2 + 1)
                              },
                              domProps: {
                                textContent: _vm._s(
                                  method.Heading !== "" &&
                                    method.Heading !== undefined
                                    ? method.Heading
                                    : +"Sida " + (index2 + 1)
                                )
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  })
                ],
                2
              )
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }